import {FC} from 'react';

interface WaywardProps {
  width?: number;
  height?: number;
}

export const Wayward: FC<WaywardProps> = ({width = 153, height = 40}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 157 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2615_88175)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1659 11.2403C46.4406 9.74202 44.0797 8.99219 41.0818 8.99219C39.3279 8.99219 37.7453 9.30355 36.3314 9.92484C35.4958 10.2762 34.7503 10.7204 34.0933 11.2574L32.8136 16.4991H36.6284C36.827 15.4808 37.3354 14.7181 38.1553 14.2082C38.9751 13.6712 39.9363 13.4027 41.0389 13.4027C42.2544 13.4027 43.2727 13.7426 44.0925 14.421C44.9123 15.0994 45.3223 16.1606 45.3223 17.6017V18.1102H39.9777C36.9241 18.1102 34.6332 18.7186 33.1064 19.9341C31.5795 21.1495 30.8154 22.7335 30.8154 24.6845C30.8154 25.8442 31.0982 26.904 31.6638 27.8652C32.2294 28.8264 33.0921 29.6048 34.2518 30.1976C35.4116 30.7631 36.8955 31.0459 38.7051 31.0459C40.5147 31.0459 41.8859 30.6789 42.9899 29.9433C44.1211 29.2078 44.998 28.2751 45.6193 27.1439L46.1278 30.5375H50.7511V17.6003C50.7511 14.8566 49.8884 12.7371 48.1631 11.2374L48.1659 11.2403ZM43.3998 25.2801C42.5514 26.2984 41.3503 26.8069 39.7949 26.8069C38.7765 26.8069 37.9567 26.5812 37.3354 26.1284C36.7127 25.6757 36.4028 25.0401 36.4028 24.2203C36.4028 23.4562 36.7141 22.8206 37.3354 22.3121C37.9567 21.7751 38.9894 21.5066 40.4319 21.5066H45.0552C44.8295 23.0048 44.2782 24.2631 43.4013 25.2815L43.3998 25.2801Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15009 30.5416L0 9.50195H5.38739L9.03517 24.6458L13.2771 9.50195H19.3001L23.5406 24.6458L27.2312 9.50195H32.6186L26.4257 30.5416H20.784L16.2879 14.8051L11.7917 30.5416H6.15009Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.8925 29.142L55.015 39.8725H60.7837L74.1451 9.50195H68.3764L62.2263 24.3059L56.3304 9.50195H50.4346L58.6199 29.142H59.8925Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.569 0V12.3859C150.834 11.3118 149.872 10.4777 148.686 9.88356C147.497 9.2894 146.112 8.99232 144.528 8.99232C142.548 8.99232 140.782 9.47365 139.226 10.4349C137.671 11.3675 136.441 12.6687 135.536 14.3369C134.631 15.9765 134.179 17.8575 134.179 19.9785C134.179 22.0995 134.631 23.9948 135.536 25.663C136.441 27.3312 137.671 28.6466 139.226 29.6078C140.782 30.569 142.548 31.0504 144.528 31.0504C146.31 31.0504 147.751 30.6962 148.854 29.9892C149.985 29.2822 150.891 28.4624 151.569 27.5297L152.163 30.5419H156.999V0H151.569ZM149.958 24.5589C148.854 25.7187 147.427 26.2985 145.673 26.2985C143.919 26.2985 142.521 25.7044 141.39 24.5175C140.259 23.3292 139.693 21.8167 139.693 19.9785C139.693 18.1403 140.259 16.6421 141.39 15.4823C142.521 14.3226 143.948 13.7427 145.673 13.7427C147.399 13.7427 148.854 14.3369 149.958 15.5238C151.089 16.6835 151.655 18.1817 151.655 20.0199C151.655 21.8581 151.089 23.3706 149.958 24.5589Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.148 30.5403V9.50058H126.985L127.493 13.4454C128.257 12.0886 129.288 11.0131 130.589 10.2218C131.918 9.40203 133.475 8.99212 135.256 8.99212L135.313 8.875L133.729 14.718C132.54 14.718 131.481 14.9023 130.548 15.2693C129.615 15.6364 128.88 16.2734 128.343 17.1775C127.834 18.0816 127.579 19.3413 127.579 20.9524V30.5388H122.15L122.148 30.5403Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.915 11.2403C115.19 9.74202 112.829 8.99219 109.831 8.99219C108.077 8.99219 106.495 9.30355 105.081 9.92484C104.651 10.1062 104.244 10.3119 103.86 10.5418L102.032 16.4991H105.376C105.575 15.4808 106.083 14.7181 106.903 14.2082C107.723 13.6712 108.684 13.4027 109.787 13.4027C111.002 13.4027 112.021 13.7426 112.841 14.421C113.66 15.0994 114.07 16.1606 114.07 17.6017V18.1102H108.726C105.672 18.1102 103.381 18.7186 101.854 19.9341C100.328 21.1495 99.5635 22.7335 99.5635 24.6845C99.5635 25.8442 99.8463 26.904 100.412 27.8652C100.977 28.8264 101.84 29.6048 103 30.1976C104.16 30.7631 105.644 31.0459 107.453 31.0459C109.263 31.0459 110.634 30.6789 111.738 29.9433C112.869 29.2078 113.746 28.2751 114.367 27.1439L114.876 30.5375H119.499V17.6003C119.499 14.8566 118.636 12.7371 116.911 11.2374L116.915 11.2403ZM112.292 25.2801C111.444 26.2984 110.243 26.8069 108.687 26.8069C107.669 26.8069 106.849 26.5812 106.228 26.1284C105.606 25.6757 105.295 25.0401 105.295 24.2203C105.295 23.4562 105.606 22.8206 106.228 22.3107C106.85 21.7737 107.882 21.5052 109.324 21.5052H113.947C113.722 23.0034 113.171 24.2617 112.294 25.2801H112.292Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.2285 19.8611L75.3507 30.5416H80.9923L85.4885 14.8051L89.9846 30.5416H95.6263L101.819 9.50195H96.4318L92.7426 24.6458L88.5007 9.50195H82.4777L78.2358 24.6458L75.3221 12.5499L72.2285 19.8611Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2615_88175">
        <rect width="157" height="40.035" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
